import React, { useState } from "react";
import Image from "components/common/utils/Image";
import "./styles.sass";
import T from "types/index";
import { AirtableImages } from "../utils/AirtableImage";

export type TestimonyCarouselItemProps = {
  info: string;
  text: string;
  patho?: string;
  image?: string;
  alt?: string;
  title?: string;
  before?: string;
  after?: string;
};
export type TestimonyCarouselProps = {
  testimonies: TestimonyCarouselItemProps[];
};
export type Props<TestimonyType> = {
  testimonies: TestimonyType[];
  renderTestimony: (item: TestimonyType) => React.ReactNode;
};

const TestimonyCarousel = <TestimonyType,>({
  testimonies,
  renderTestimony
}: Props<TestimonyType>) => {
  const [testimonyCarouselIndex, setTestimonyCarouselIndex] = useState(0);

  function iOS() {
    return (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
  }

  function slideToNext() {
    const carousel = document.getElementById("testimony-carousel");
    const nextSlide = document.getElementById(
      `testimony-${testimonyCarouselIndex + 1}`
    );
    if (nextSlide) {
      const testimony0 = document.getElementById("testimony-0");
      if (testimony0 != null && carousel != null) {
        const left = testimony0.offsetLeft;
        const offsetX = nextSlide.offsetLeft - left + 1;
        carousel.scrollTo(offsetX, 0);
        if (iOS() === true) {
          setTestimonyCarouselIndex(testimonyCarouselIndex + 1);
        }
      }
    }
  }

  function slideToPrev() {
    const carousel = document.getElementById("testimony-carousel");
    const prevSlide = document.getElementById(
      `testimony-${testimonyCarouselIndex - 1}`
    );
    if (prevSlide) {
      const testimony0 = document.getElementById("testimony-0");
      if (testimony0 != null && carousel != null) {
        const left = testimony0.offsetLeft;
        const offsetX = prevSlide.offsetLeft - left + 1;
        carousel.scrollTo(offsetX, 0);
        if (iOS() === true) {
          setTestimonyCarouselIndex(testimonyCarouselIndex - 1);
        }
      }
    }
  }

  function setCurrentSlide(e: any) {
    const element = e.target;
    const x = element.scrollLeft;
    const children = element.childNodes[0].childNodes;
    const array = Array.from(children);
    const bulletPoint = Array.from(
      document.getElementsByClassName("testimony-carousel-remote-bullet-point")
    );
    array.forEach((child: any, i) => {
      const zero = child.offsetLeft - children[0].offsetLeft - 50;
      if (x > zero && x < zero + child.offsetWidth) {
        setTestimonyCarouselIndex(i);
      }
    });
    bulletPoint.forEach((bullet: any, i) => {
      if (
        testimonyCarouselIndex === i &&
        bullet.style.backgroundColor !== "black"
      ) {
        bullet.style.backgroundColor = "black";
      } else if (
        bullet.style.backgroundColor !== "#C4C4C4" &&
        testimonyCarouselIndex !== i
      ) {
        bullet.style.backgroundColor = "#C4C4C4";
      }
    });

    const addClassToElementWithId = (elementId: string, className: string) => {
      const element = document.getElementById(elementId);
      if (element != null) {
        element.classList.add(className);
      }
    };
    const removeClassFromElementWithId = (
      elementId: string,
      className: string
    ) => {
      const element = document.getElementById(elementId);
      if (element != null) {
        element.classList.remove(className);
      }
    };
    if (testimonyCarouselIndex === 0) {
      addClassToElementWithId(
        "carousel-button-prev",
        "carousel-button-tag-disabled"
      );
    } else if (testimonyCarouselIndex === 2) {
      addClassToElementWithId(
        "carousel-button-next",
        "carousel-button-tag-disabled"
      );
    } else {
      removeClassFromElementWithId(
        "carousel-button-prev",
        "carousel-button-tag-disabled"
      );
      removeClassFromElementWithId(
        "carousel-button-next",
        "carousel-button-tag-disabled"
      );
    }
  }

  return (
    <div className="landing-testimony-whole-carousel">
      <div
        id="testimony-carousel"
        className="landing-testimony-section-card"
        onScroll={setCurrentSlide}
      >
        <div className="landing-testimony-section-card-carousel">
          {testimonies.map((testimony: TestimonyType, i: number) => (
            <div
              id={`testimony-${i}`}
              key={`testimony-${i}`}
              className="landing-testimony-section-card-content"
            >
              {renderTestimony(testimony)}
            </div>
          ))}
        </div>
      </div>
      <div className="landing-testimony-section-carousel-remote">
        <div
          id="carousel-button-prev"
          className="carousel-button-tag carousel-button-tag-disabled"
          onClick={slideToPrev}
        >
          <Image
            filename="new-images/tag.svg"
            alt="testimony-tag"
            title="testimony tag"
          />
        </div>

        <div className="testimony-carousel-remote-bullet-point-wrapper">
          <div className="testimony-carousel-remote-bullet-point" />
          <div className="testimony-carousel-remote-bullet-point" />
          <div className="testimony-carousel-remote-bullet-point" />
        </div>
        <div
          id="carousel-button-next"
          className="carousel-button-tag "
          onClick={slideToNext}
        >
          <Image
            filename="new-images/tag.svg"
            alt="testimony-tag-3"
            title="testimony tag 3"
          />
        </div>
      </div>
    </div>
  );
};

// Legacy
type TextOrBeforeAfterTestimony = {
  info: string;
  text: string;
  patho?: string;
  image?: string;
  alt?: string;
  title?: string;
  before?: string;
  after?: string;
};

export const textOrBeforeAfterTestimonyRenderer = (
  testimony: TextOrBeforeAfterTestimony
) => (
  <>
    <div className="landing-testimony-section-card-image">
      {testimony.image && (
        <Image
          filename={testimony.image}
          alt={`${testimony.alt}-${testimony.info}`}
          title={`${testimony.title}-${testimony.info}`}
        />
      )}
      {testimony.patho && (
        <div className="before-after-div-content">
          <div className="before-div">{testimony.before}</div>
          <div className="after-div">{testimony.after}</div>
        </div>
      )}
    </div>
    {testimony.patho ? (
      <div className="landing-testimony-section-card-info">
        {testimony.info}
      </div>
    ) : (
      <div className="landing-testimony-section-card-info">
        {testimony.info}
      </div>
    )}
    <div className="landing-testimony-section-card-text">{testimony.text}</div>
  </>
);

export const airtableTestimonyRenderer = (
  testimony: T.Charles.TestimonyRecord
) => {
  const versionWithImage = testimony.data.before != null;
  return versionWithImage ? (
    <>
      <div className="landing-testimony-section-card-image">
        <AirtableImages className="img-fluid" images={testimony.data.image} />
        <div className="before-after-div-content">
          <div className="before-div">{testimony.data.before}</div>
          <div className="after-div">{testimony.data.after}</div>
        </div>
      </div>

      <div className="landing-testimony-section-card-info">
        {testimony.data.author}
      </div>
      <div className="landing-testimony-section-card-text">
        {testimony.data.text}
      </div>
    </>
  ) : (
    <>
      <div className="landing-testimony-section-card-info">
        {testimony.data.author}
      </div>
      <div className="landing-testimony-section-card-text">
        {testimony.data.text}
      </div>
    </>
  );
};

export default TestimonyCarousel;
