import React from "react";
import { Link } from "gatsby";
import Image from "components/common/utils/Image";
import cx from "classnames";
import "./styles.sass";

type Props = {
  withBorders?: boolean
  className?: string
}
const PressBanner = ({ className, withBorders = false }: Props) => (
  <div className={cx("landing-home-press-banner", { "press-banner-with-borders": withBorders }, className)}>
    <div className="h4 press-banner-separator">Ils parlent de nous</div>
    <div className="text-center interview-carousel-div">
      <Image
        filename="press/france-inter.svg"
        title="france inter logo"
        alt="france-inter-logo"
      />
      <Image
        filename="press/leMonde.svg"
        title="le monde logo"
        alt="le-monde-logo"
      />
      <Image
        filename="press/gq.svg"
        title="gq logo"
        alt="gq-logo" />
      <Image
        filename="press/france-info.svg"
        title="france info logo"
        alt="france-info-logo"
      />
    </div>
    <Link to="/presse/" className="press-banner-find-out-more">
      <div className="find-out-more-text">En savoir plus</div>
      <Image
        filename="new-images/arrow-link.svg"
        alt="fleche-droite-fine-picto"
        title="fleche  droite fine picto"
      />
    </Link>
  </div>
);

export default PressBanner;
