import { Link } from "gatsby";

import React from "react";
import { get } from "utils/lodashExtracts";
import { citySlug } from "templates/Annuary/utils/slugs";
import T from "types/index";
import use12TopCities from "hooks/use12TopCities"

type Props = {
  cities?: T.Charles.CityRecord[];
};
const FooterCities = ({ cities = use12TopCities() }: Props) => {

  return (
  <div className="annuary-search-footer-div">
    {cities.map((city) => {
      const departmentSlug = get(city, "data.departments[0].data.slug");
      return (
        <h3 className="footer-city-wrapper">
          <Link
            to={citySlug(departmentSlug, city.data.slug)}
          >
            {`Sexologues ${city.data.name}`}
          </Link>
        </h3>
      );
    })}
  </div>
)};
export default FooterCities;
