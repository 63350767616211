export const citySlug = (department, city) => {

  const shouldIncludeDepartment = !isCitySameAsDepartment(city)
  return shouldIncludeDepartment ? `/sexologue/${department}/${city}/` : `/sexologue/${city}/`
};

export const isCitySameAsDepartment = (city) => {
  return(city === 'paris')
}

export const departmentSlug = (department) => {
  return `/sexologue/${department}/`
}

export const annuarySlug = () => `/sexologue/`;

export const homeSlug = () => `/`;
