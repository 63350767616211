import { useStaticQuery, graphql } from "gatsby"
import T from "types/index";

export default () : T.Charles.DepartmentRecord[] => {
  const { allAirtableDepartments: { nodes } } = useStaticQuery(graphql`
    query {
      allAirtableDepartments(
        sort: { fields: data___code_order, order: ASC }
        filter: { data: { active_cities_count: { gt: 0 }}}
      ) {
        nodes {
          id
          data {
            name
            code
            slug
            A_LIEU
          }
        }
      }
    }
  `);
  return nodes
}
