import { Link } from "gatsby";
import useAirtableDepartments from "hooks/useAirtableDepartments";

import React from "react";
import { departmentSlug } from "templates/Annuary/utils/slugs";
import T from "types/index";

type Props = {
  departments?: T.Charles.DepartmentRecord[];
};
const FooterDepartments = ({
  departments = useAirtableDepartments(),
}: Props) => (
  <div className="annuary-search-footer-div">
    {departments.map((d) => (
      <h3 className="footer-city-wrapper">
        <Link to={departmentSlug(d.data.slug)}>Sexologues {d.data.A_LIEU}</Link>
      </h3>
    ))}
  </div>
);

export default FooterDepartments;
