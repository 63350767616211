import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Section from 'components/common/Section';
import Cta from 'components/common/Cta';
import TestimonyCarousel, { airtableTestimonyRenderer } from 'components/common/TestimonyCarousel';
import { Charles, Standard } from 'types/index';
import { AirtableImages } from 'components/common/utils/AirtableImage';
import { AirtableMarkdown } from 'components/common/utils/AirtableMarkdown';

import './styles.sass'
import { isEmpty } from 'utils/lodashExtracts';

type Props = {
  testimonies_background: Charles.ImageRecord[]
  testimonies_percent: Standard.Markdown
  testimonies_list: Charles.TestimonyRecord[]
  cta_label: string
  start_slug: string
}

const TestimonyPathologyTopBottom = (props: Props) => {
  return isEmpty(props.testimonies_list) ? null : (
    <Section className="pathology-testimony-section">
      <div id="testimony-anchor" className="pathology-testimony-anchor-top-bottom pathology-anchor" />
      <div className="pathology-testimony-background-wrapper" style={{ display: 'grid' }}>
        <div className="background-image-part-pathology-testimony">
          <AirtableImages
            className="h-100"
            images={props.testimonies_background}
          />
        </div>
        <div
          className="content-part-pathology-testimony"
          style={{
            // By using the same grid area for both, they are stacked on top of each other
            gridArea: '1/1',
            position: 'relative',
          }}
        >
          <div className="top-content-testimony">Résultats</div>
          <div className="main-content-testimony"><AirtableMarkdown markdown={props.testimonies_percent} /></div>
        </div>
      </div>
      <div className="pathology-testimony-card-content d-xl-none">
        <TestimonyCarousel testimonies={props.testimonies_list} renderTestimony={airtableTestimonyRenderer} />
        <Cta text={props.cta_label} className="new-primary-cta pathology-testimony-cta d-md-none" path={props.start_slug} />
      </div>
      <Container className="d-none d-xl-flex pathology-testimony-xl-card-wrapper">
        <Row>
          {props.testimonies_list.map((testimony, i) => (
            <Col xl={4} key={i}>
              <div className="charles-testimony-card h-100">
                {airtableTestimonyRenderer(testimony)}
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </Section>
  )
}

export default TestimonyPathologyTopBottom;
